import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";

import "pure-react-carousel/dist/react-carousel.es.css";
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="bg-[url('/images/bot-bg.png')] bg-cover p-[9rem] lg:mt-[-3rem]">
        
       
    <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4 place-items-center ">
  <div className="slide-right">
  <h1 className="mt-4 bg2 text-white">Lorem ipsum dolor
sit amet</h1>
  <p className="mt-4 fs12 text-white"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
 </div>
  
  <div className="slide-left ">

  <form >
  <h1 className="mt-4  hc text-white">Book A Demo</h1>
  <div className="mb-4  mt-[1rem] ">
    
    <input type="text"    placeholder="Name"  className=" clf shadow-sm text-gray-900 text-sm rounded-lg  block w-full p-5 "  />
  </div>
  <div className="mb-4">
  
    <input type="email"  placeholder="Email"  className="clf shadow-sm  text-sm rounded-lg  block w-full p-5 " />
  </div>
  <div className="mb-6">
  
    <input    placeholder="Number" className="clf shadow-sm  block w-full p-5" />
  </div>
 <p className="fs12 text-white">By proceeding, I agree to Rapid Accelaration Partners, Inc’s Terms of Use and acknowledge<br/>that I have read the Privacy Policy.</p>
  <p className="fs12 text-white">I also agree that Rapid Acceleration Partners, Inc or its representatives may contact me by <br/>email, phone, at the email address or number I provide for marketing purposes.</p>
  <button type="submit" className="bookbtn booktext text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300  text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Book Now</button>
  <div className="message"></div>
</form>

  
  </div>
  
</div>
          
      
      
                </div>
    






  
  </Layout>
)

export default IndexPage
